import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    data() {
        return {
            fontSize: {
                header: 10,
                footer: 8,
                body: 12,
                table: 9
            },
            margin: {
                top: 15,
                bottom: 10,
                left: 10,
                right: 10,
            },
            padding: 2,
            headerMessage: "American Smart Lending | info@AmericanSmartLending.com"
        }
    },
    methods: {
        // format number to display 2 decimal digits and add comma seperator
        formatNumber: function (amount) {
            var formattedAmount = Number(amount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return formattedAmount;
        },

        // calculate the position y for given line number
        calculateY(lineCounter) {
            var y = this.margin.top + this.padding + (0.5 * lineCounter * this.fontSize.body);
            // return y
            return y;
        },

        // generate an array of rows with annual schedule details
        generateAnnualTable: function (annualSchedule) {
            var head = [
                ['Year', 'Monthly Payment', 'Interest Rate', 'Interest Paid', 'Cumulative Interest', 'Principal Paid', 'Loan Balance']
            ]
            // empty body array
            var body = [];
            // loop through annual schedule
            for (var i = 0; i < annualSchedule.length; i++) {
                // empty row array
                var row = [];
                // current year in loop
                var year = annualSchedule[i];

                // insert required fields in rows
                row.push(year["currentYear"]);
                row.push("$" + this.formatNumber(year["monthlyPayment"]));
                // row.push("$" + this.formatNumber(year["annualPayment"]));
                row.push(year["interestRate"] + "%");
                row.push("$" + this.formatNumber(year["annualInterestPaid"]));
                row.push("$" + this.formatNumber(year["cumulativeAnnualInterest"]));
                row.push("$" + this.formatNumber(year["annualPrincipalPaid"]));
                row.push("$" + this.formatNumber(year["annualBalance"]));

                // push each row to the body
                body.push(row);
            }
            // return body
            return {
                head,
                body
            };
        },

        // generate an array of rows with monthly schedule details
        generateMonthlyTable: function (monthlySchedule) {
            var head = [
                ['Month', 'Monthly Payment', 'Interest Rate', 'Interest Paid', 'Cumulative Interest', 'Principal Paid', 'Loan Balance']
            ]
            // empty body array
            var body = [];
            // loop through monthly schedule
            for (var i = 0; i < monthlySchedule.length; i++) {
                // empty row array
                var row = [];
                // current month in loop
                var month = monthlySchedule[i];

                // insert required fields in rows
                row.push(month["currentMonth"]);
                row.push("$" + this.formatNumber(month["monthlyPayment"]));
                row.push(month["interestRate"] + "%");
                row.push("$" + this.formatNumber(month["monthlyInterestPaid"]));
                row.push("$" + this.formatNumber(month["cumulativeMonthlyInterest"]));
                row.push("$" + this.formatNumber(month["monthlyPrincipalPaid"]));
                row.push("$" + this.formatNumber(month["loanBalance"]));

                // push each row to the body
                body.push(row);

                // add a blank row after 12 months
                if ((i + 1) % 12 === 0)
                    body.push([]);
            }

            // return body
            return {
                head,
                body
            };
        },

        writeTable: function (doc, tableHeading, tableBody, startY) {
            // write table to document
            doc.autoTable({
                // didDrawPage will be written on every page
                didDrawPage: function () {
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    var pageSize = doc.internal.pageSize;
                    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

                    // Header
                    doc.setFontSize(this.fontSize.header);
                    doc.text(this.headerMessage, this.margin.left * 3 / 2, this.margin.top * 2 / 3);
                    // draw line below header
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.5);
                    var marginDiff = Math.abs(this.margin.top - this.margin.left) - this.padding;
                    doc.line(this.margin.left, this.margin.top - marginDiff, pageWidth - this.margin.left, this.margin.top - marginDiff); // horizontal line  

                    // Footer
                    var str = "Page " + doc.internal.getNumberOfPages();
                    doc.setFontSize(this.fontSize.footer);
                    doc.text(str, pageWidth / 2, pageHeight - this.fontSize.footer, 'center');
                }.bind(this),
                // start position of table
                startY: startY,
                // margin for table
                margin: {
                    top: this.margin.top,
                    bottom: this.margin.bottom
                },
                // table heading style
                headStyles: {
                    fillColor: [0, 0, 0],
                },
                // table body style
                styles: {
                    cellPadding: 0.5,
                    overflow: 'linebreak',
                    fontSize: this.fontSize.table
                },
                // tableLineColor: [0, 0, 0], //choose RGB
                // tableLineWidth: 0.5, //table border width
                // available themes: 'striped', 'grid', 'plain'
                theme: 'striped',
                // table heading for monthly schedule
                head: tableHeading,
                // table body
                body: tableBody,
            });

            let finalY = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
            return finalY;
        },

        writeLoanInfo: function (doc, lineCounter, schedule) {
            // monthly payment
            doc.setFontSize(this.fontSize.body);
            var monthlyPaymentStr = "$" + this.formatNumber(schedule.monthlySchedule[0].monthlyPayment);
            doc.text("\tMonthly Payment: " + monthlyPaymentStr, this.margin.left, this.calculateY(lineCounter));
            lineCounter++;

            // loan amount
            var loanAmountStr = "$" + this.formatNumber(schedule.totalPrincipal);
            doc.text("\tLoan Amount: " + loanAmountStr, this.margin.left, this.calculateY(lineCounter));
            lineCounter++;

            // interest rate
            var interestRateStr = schedule.interestRate + "%";
            doc.text("\tInterest Rate: " + interestRateStr, this.margin.left, this.calculateY(lineCounter));
            lineCounter++;

            // mortgage term
            var termStr = schedule.term;
            doc.text("\tMortgage Term: " + termStr, this.margin.left, this.calculateY(lineCounter));
            lineCounter++;

            return lineCounter;
        },

        /* eslint-disable */
        generateAmortizationPdf: function (schedules) {
            // return a promise
            return new Promise(resolve => {
                // jsPDF document to write schedule
                const doc = new jsPDF();

                for (var i = 0; i < schedules.length; i++) {

                    // get documentWidth
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    var pageSize = doc.internal.pageSize;
                    // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

                    // write mortgage info to document
                    if (i !== 0)
                        doc.addPage();

                    // line counter for current page
                    var lineCounter = 1;

                    // write loan info to document
                    lineCounter = this.writeLoanInfo(doc, lineCounter, schedules[i]);

                    // write heading for Annual Schedule
                    doc.setFontStyle('bold');
                    doc.text("Yearly Schedule", pageWidth / 2, this.calculateY(lineCounter), 'center');
                    lineCounter++;

                    // generate body for annual schedule
                    var annualTable = this.generateAnnualTable(schedules[i].annualSchedule);

                    // write the generated table to pdf
                    this.writeTable(doc, annualTable.head, annualTable.body, this.calculateY(lineCounter));

                    // start monthly schedule in new page
                    doc.addPage();

                    // line counter for current page
                    var lineCounter = 1;

                    // write heading for Monthly Schedule
                    doc.setFontStyle('bold');
                    doc.text("Monthly Schedule", pageWidth / 2, this.calculateY(lineCounter), 'center');
                    lineCounter++;

                    // generate body for monthly schedule
                    var monthlyTable = this.generateMonthlyTable(schedules[i].monthlySchedule);

                    // write the generated table to pdf
                    this.writeTable(doc, monthlyTable.head, monthlyTable.body, this.calculateY(lineCounter));
                }

                // return pdf document
                resolve(doc);
                // return doc;
            });
        }
    }
}
