<template>
  <div class="wrapper">
    <!-- Main -->
    <section class="main">
      <!-- Dashboard -->
      <section class="dashboard row">
        <div class="col-6 col-12-medium">
          <!-- Form -->
          <form id="amortization-calculator" class="box" v-on:submit.prevent="getSchedule">
            <h1>Amortization Calculator</h1>
            <p class="instruction">
              Required fields are followed by
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>.
            </p>
            <hr>
            <!-- Mortgage Information -->
            <section>
              <!-- Mortgage Amount -->
              <p class="mortgage-amount">
                <label for="mortgage-amount">
                  <span class="field-name">Mortgage Amount:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  id="mortgage-amount"
                  type="text"
                  placeholder="$ 320,000"
                  maxlength="20"
                  v-model.lazy="mortgageAmount"
                  v-money="mortgageAmount !== null ? currencyFormat : null"
                  v-on:blur="validateMortgageAmount"
                  required
                >
                <!-- An icon will be inserted below after validating the above input -->
                <validation-icon v-bind:isValid="validations.mortgageAmount"></validation-icon>
              </p>
              <!-- Mortgage Term -->
              <fieldset class="mortgage-term">
                <legend>
                  <span class="field-name">Mortgage Term:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                  <!-- An icon will be inserted below after validating the above input -->
                  <validation-icon v-bind:isValid="validations.mortgageTerm"></validation-icon>
                </legend>
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      id="arm7"
                      value="7"
                      v-model="mortgageTerm"
                      v-on:change="validateMortgageTerm"
                    >
                    <label for="arm7">7 year ARM</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="fixed10"
                      value="10"
                      v-model="mortgageTerm"
                      v-on:change="validateMortgageTerm"
                    >
                    <label for="fixed10">10 year fixed</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="fixed15"
                      value="15"
                      v-model="mortgageTerm"
                      v-on:change="validateMortgageTerm"
                    >
                    <label for="fixed15">15 year fixed</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="fixed20"
                      value="20"
                      v-model="mortgageTerm"
                      v-on:change="validateMortgageTerm"
                    >
                    <label for="fixed20">20 year fixed</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="fixed25"
                      value="25"
                      v-model="mortgageTerm"
                      v-on:change="validateMortgageTerm"
                    >
                    <label for="fixed25">25 year fixed</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="fixed30"
                      value="30"
                      v-model="mortgageTerm"
                      v-on:change="validateMortgageTerm"
                    >
                    <label for="fixed30">30 year fixed</label>
                  </li>
                </ul>
              </fieldset>

              <!-- interest rates -->
              <div class="interest-rate">
                <p>
                  <span class="field-name">Interest rate per year:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                  <!-- An icon will be inserted below after validating the above input -->
                  <validation-icon v-bind:isValid="validations.interestRates"></validation-icon>
                  <br>
                  <!-- interest rate for 7 year term -->
                  <span v-show="terms[7].isChecked" class="term">
                    <input
                      id="interest-rate-7-year"
                      type="text"
                      maxlength="5"
                      v-model.lazy="terms[7].interestRate"
                      v-on:change="validateInterestRates"
                    >
                    <span class="percentage">%</span>
                    <label for="interest-rate-7-year">7 year</label>
                  </span>
                  <!-- interest rate for 10 year term -->
                  <span v-show="terms[10].isChecked" class="term">
                    <input
                      id="interest-rate-10-year"
                      type="text"
                      maxlength="5"
                      v-model.lazy="terms[10].interestRate"
                      v-on:change="validateInterestRates"
                    >
                    <span class="percentage">%</span>
                    <label for="interest-rate-10-year">10 year</label>
                  </span>
                  <!-- interest rate for 15 year term -->
                  <span v-show="terms[15].isChecked" class="term">
                    <input
                      id="interest-rate-15-year"
                      type="text"
                      maxlength="5"
                      v-model.lazy="terms[15].interestRate"
                      v-on:change="validateInterestRates"
                    >
                    <span class="percentage">%</span>
                    <label for="interest-rate-15-year">15 year</label>
                  </span>
                  <!-- interest rate for 20 year term -->
                  <span v-show="terms[20].isChecked" class="term">
                    <input
                      id="interest-rate-20-year"
                      type="text"
                      maxlength="5"
                      v-model.lazy="terms[20].interestRate"
                      v-on:change="validateInterestRates"
                    >
                    <span class="percentage">%</span>
                    <label for="interest-rate-20-year">20 year</label>
                  </span>
                  <!-- interest rate for 25 year term -->
                  <span v-show="terms[25].isChecked" class="term">
                    <input
                      id="interest-rate-25-year"
                      type="text"
                      maxlength="5"
                      v-model.lazy="terms[25].interestRate"
                      v-on:change="validateInterestRates"
                    >
                    <span class="percentage">%</span>
                    <label for="interest-rate-25-year">25 year</label>
                  </span>
                  <!-- interest rate for 30 year term -->
                  <span v-show="terms[30].isChecked" class="term">
                    <input
                      id="interest-rate-30-year"
                      type="text"
                      maxlength="5"
                      v-model.lazy="terms[30].interestRate"
                      v-on:change="validateInterestRates"
                    >
                    <span class="percentage">%</span>
                    <label for="interest-rate-30-year">30 year</label>
                  </span>
                </p>
              </div>

              <!-- Submit button -->
              <button type="submit" class="primary">
                <span v-if="mortgageTerm.length>1">Compare</span>
                <span v-if="mortgageTerm.length<=1">Calculate</span>
              </button>
            </section>
          </form>
        </div>
        <!-- Results and Summary -->
        <div class="col-6 col-12-medium">
          <!-- Result when not comparing -->
          <div class="result" v-show="(!isComparison || !gotSchedule)">
            <h2>Monthly Payment</h2>
            <h1>${{schedules[0].monthlySchedule[0].monthlyPayment}}</h1>

            <ul class="alt">
              <li>
                <!-- Total Principal paid -->
                <span class="float-left">Total Principal paid:</span>
                <span class="float-right">${{formatNumber(schedules[0].totalPrincipal)}}</span>
              </li>
              <li>
                <!-- Mortgage Term -->
                <span class="float-left">Mortgage Term:</span>
                <span class="float-right">{{schedules[0].term}}</span>
              </li>
              <li>
                <!-- Total Interest paid -->
                <span class="float-left">Total Interest paid:</span>
                <span class="float-right">${{formatNumber(schedules[0].totalInterest)}}</span>
              </li>
              <li>
                <!-- Interest rate per year -->
                <span class="float-left">Interest rate per year:</span>
                <span class="float-right">{{schedules[0].interestRate}}%</span>
              </li>
            </ul>
          </div>
          <!-- summary when comparing -->
          <div class="summary" v-show="isComparison && gotSchedule">
            <h2>Comparison Summary</h2>
            <hr class="major">
            <br>
            <!-- Comparison Table -->
            <table>
              <thead>
                <tr>
                  <th>Term</th>
                  <th>Monthly Payment</th>
                  <th>Total Interest</th>
                  <th>Total Principal</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(schedule, index) in schedules"
                  v-bind:schedule="schedule"
                  v-bind:key="index"
                >
                  <td>{{schedule.term}}</td>
                  <td>${{schedule.monthlySchedule[0].monthlyPayment}}</td>
                  <td>${{formatNumber(schedule.totalInterest)}}</td>
                  <td>${{formatNumber(schedule.totalPrincipal)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Download pdf button -->
          <div class="download-pdf-wrapper">
            <button
              type="button"
              class="primary download-pdf"
              v-on:click.prevent="downloadPDF"
              v-bind:disabled="(pdfGenerated && gotSchedule)? null: 'disabled'"
            >
              Download PDF
              <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>
          <!-- Hide Schedule button -->
          <p
            v-show="gotSchedule && hideSchedule===false"
            @click="hideSchedule=true"
            class="hide"
            v-scroll-to="{ element: '#amortization-calculator', offset: -75}"
          >
            hide schedule
            <span class="icon fa-angle-up"></span>
          </p>
          <!-- Show Schedule button -->
          <p
            v-show="gotSchedule && hideSchedule===true"
            @click="hideSchedule=false"
            class="show"
            v-scroll-to="{ element: '#schedule', offset: -75}"
          >
            show schedule
            <span class="icon fa-angle-down"></span>
          </p>
        </div>
        <!-- end dashboard -->
      </section>
      <!-- Amortization Schedule -->
      <section v-show="gotSchedule && !hideSchedule" id="schedule" ref="schedule">
        <!-- Schedule when not comparing Tab -->
        <tabs v-show="!isComparison">
          <!-- Monthly Schedule -->
          <tab name="Monthly Schedule" :selected="true">
            <!-- Amortization Table -->
            <table>
              <!-- Table Heading -->
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Monthly Payment</th>
                  <th>Interest Rate</th>
                  <th>Interest Paid</th>
                  <th>Cumulative Interest</th>
                  <th>Principal Paid</th>
                  <th>Loan Balance</th>
                </tr>
              </thead>
              <!-- Table body -->
              <tbody>
                <!-- Loop throught each month in schedule -->
                <tr
                  v-for="(month, index) in schedules[0].monthlySchedule"
                  v-bind:month="month"
                  v-bind:key="index"
                >
                  <td>{{month.currentMonth}}</td>
                  <td>${{formatNumber(month.monthlyPayment)}}</td>
                  <td>{{month.interestRate}}%</td>
                  <td>${{formatNumber(month.monthlyInterestPaid)}}</td>
                  <td>${{formatNumber(month.cumulativeMonthlyInterest)}}</td>
                  <td>${{formatNumber(month.monthlyPrincipalPaid)}}</td>
                  <td>${{formatNumber(month.loanBalance)}}</td>
                </tr>
              </tbody>
            </table>
          </tab>
          <!-- Annual Schedule Tab -->
          <tab name="Annual Schedule">
            <!-- Amortization Table -->
            <table>
              <!-- Table Heading -->
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Monthly Payment</th>
                  <th>Interest Rate</th>
                  <th>Interest paid annually</th>
                  <th>Cumulative Interest paid</th>
                  <th>Principal paid annually</th>
                  <th>Loan Balance</th>
                </tr>
              </thead>
              <!-- Table body -->
              <tbody>
                <!-- Loop throught each year in schedule -->
                <tr
                  v-for="(year, index) in schedules[0].annualSchedule"
                  v-bind:year="year"
                  v-bind:key="index"
                >
                  <td>{{year.currentYear}}</td>
                  <td>${{formatNumber(year.monthlyPayment)}}</td>
                  <td>{{year.interestRate}}%</td>
                  <td>${{formatNumber(year.annualInterestPaid)}}</td>
                  <td>${{formatNumber(year.cumulativeAnnualInterest)}}</td>
                  <td>${{formatNumber(year.annualPrincipalPaid)}}</td>
                  <td>${{formatNumber(year.annualBalance)}}</td>
                </tr>
              </tbody>
            </table>
          </tab>
        </tabs>
        <!-- Comparing schedules -->
        <tabs v-show="isComparison" class="compare-schedules">
          <!-- Monthly Schedule -->
          <tab name="Monthly Schedule" class="row">
            <!-- loop through schedules for all mortgage terms selected -->
            <div
              v-for="(schedule, index) in schedules"
              v-bind:schedule="schedule"
              v-bind:key="index"
              class="col-6 col-12-medium compare-tables"
            >
              <!--Table title for ARM mortgage loans -->
              <div class="schedule-heading" v-if="!schedule.isARM">
                <h3>{{schedule.term}} Mortgage Schedule</h3>
                <br>
                <ul class="alt">
                  <li>
                    <span class="float-left">Monthly payment:</span>
                    <span
                      class="float-right"
                    >${{formatNumber(schedule.monthlySchedule[0].monthlyPayment)}}</span>
                  </li>
                  <li>
                    <span class="float-left">Interest rate:</span>
                    <span
                      class="float-right"
                    >{{formatNumber(schedule.monthlySchedule[0].interestRate)}}%</span>
                  </li>
                </ul>
              </div>
              <!-- Table title for fixed mortgage loans -->
              <div class="schedule-heading" v-if="schedule.isARM">
                <h3>{{schedule.term}} Mortgage Schedule</h3>
                <ul class="alt">
                  <li>
                    <span class="float-left">Monthly payment for first 7 years:</span>
                    <span
                      class="float-right"
                    >${{formatNumber(schedule.monthlySchedule[0].monthlyPayment)}}</span>
                  </li>
                  <li>
                    <span class="float-left">Interest rate for first 7 years:</span>
                    <span
                      class="float-right"
                    >{{formatNumber(schedule.monthlySchedule[0].interestRate)}}%</span>
                  </li>
                  <li>
                    <span class="float-left">Max monthly payment after adjustment:</span>
                    <span
                      class="float-right"
                    >${{formatNumber(schedule.monthlySchedule[359].monthlyPayment)}}</span>
                  </li>
                  <li>
                    <span class="float-left">Max interest rate after adjustment:</span>
                    <span
                      class="float-right"
                    >{{formatNumber(schedule.monthlySchedule[359].interestRate)}}%</span>
                  </li>
                </ul>
              </div>
              <!-- Amortization table -->
              <table class="col-6 col-12-medium alt">
                <!-- Table headings -->
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>interest paid</th>
                    <th>Cumulative Interest</th>
                    <th>Principal Paid</th>
                    <th>Loan Balance</th>
                  </tr>
                </thead>
                <!-- Table body -->
                <tbody>
                  <tr
                    v-for="(month, index) in schedule.monthlySchedule"
                    v-bind:month="month"
                    v-bind:key="index"
                  >
                    <td>{{month.currentMonth}}</td>
                    <td>${{formatNumber(month.monthlyInterestPaid)}}</td>
                    <td>${{formatNumber(month.cumulativeMonthlyInterest)}}</td>
                    <td>${{formatNumber(month.monthlyPrincipalPaid)}}</td>
                    <td>${{formatNumber(month.loanBalance)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tab>
          <!-- Annual Schedule Tab -->
          <tab name="Annual Schedule" :selected="true" class="row">
            <!-- loop through schedules for all mortgage terms selected -->
            <div
              v-for="(schedule, index) in schedules"
              v-bind:schedule="schedule"
              v-bind:key="index"
              class="col-6 col-12-medium compare-tables"
            >
              <!-- Table title for ARM mortgage loans -->
              <div class="schedule-heading" v-if="schedule.isARM">
                <h3>{{schedule.term}} Mortgage Schedule</h3>
                <ul class="alt">
                  <li>
                    <span class="float-left">Monthly payment for first 7 years:</span>
                    <span
                      class="float-right"
                    >${{formatNumber(schedule.monthlySchedule[0].monthlyPayment)}}</span>
                  </li>

                  <li>
                    <span class="float-left">Interest rate for first 7 years:</span>
                    <span
                      class="float-right"
                    >{{formatNumber(schedule.monthlySchedule[0].interestRate)}}%</span>
                  </li>

                  <li>
                    <span class="float-left">Max monthly payment after 7 years:</span>
                    <span
                      class="float-right"
                    >${{formatNumber(schedule.monthlySchedule[359].monthlyPayment)}}</span>
                  </li>

                  <li>
                    <span class="float-left">Max interest rate after 7 years:</span>
                    <span
                      class="float-right"
                    >{{formatNumber(schedule.monthlySchedule[359].interestRate)}}%</span>
                  </li>
                </ul>
              </div>
              <!--Table title for fixed mortgage loans -->
              <div class="schedule-heading" v-if="!schedule.isARM">
                <h3>{{schedule.term}} Mortgage Schedule</h3>
                <br>
                <ul class="alt">
                  <li>
                    <span class="float-left">Monthly payment:</span>
                    <span
                      class="float-right"
                    >${{formatNumber(schedule.monthlySchedule[0].monthlyPayment)}}</span>
                  </li>

                  <li>
                    <span class="float-left">Interest rate:</span>
                    <span
                      class="float-right"
                    >{{formatNumber(schedule.monthlySchedule[0].interestRate)}}%</span>
                  </li>
                </ul>
              </div>
              <!-- Amortization table -->
              <table class="alt">
                <!-- Table headings -->
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Interest paid annually</th>
                    <th>Cumulative Interest</th>
                    <th>Principal paid annually</th>
                    <th>Loan Balance</th>
                  </tr>
                </thead>
                <!-- Table body -->
                <tbody>
                  <tr
                    v-for="(year, index) in schedule.annualSchedule"
                    v-bind:year="year"
                    v-bind:key="index"
                  >
                    <td>{{year.currentYear.substring(6)}}</td>
                    <td>${{formatNumber(year.annualInterestPaid)}}</td>
                    <td>${{formatNumber(year.cumulativeAnnualInterest)}}</td>
                    <td>${{formatNumber(year.annualPrincipalPaid)}}</td>
                    <td>${{formatNumber(year.annualBalance)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tab>
        </tabs>
      </section>
    </section>
  </div>
</template>

<script>
import amortizationMixin from "@/mixins/amortizationMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import validationsMixin from "@/mixins/validationsMixin.js";
import pdfMixin from "@/mixins/pdfMixin.js";

export default {
  // component name
  name: "AmortizationCalculator",
  // mixins used in this component
  mixins: [amortizationMixin, inputMasksMixin, validationsMixin, pdfMixin],

  data() {
    return {
      mortgageAmount: null,
      mortgageTerm: ["15"],
      gotSchedule: false,
      pdfGenerated: false,
      isComparison: false,
      hideSchedule: true,
      schedules: [],
      amortizationPdf: null,
      currencyFormat: {
        // input masking for currency
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 0
      },
      terms: {
        // 30 year fixed mortgage term
        30: {
          months: 30 * 12,
          interestRate: 4,
          isARM: false,
          fixedTerm: 30,
          isChecked: false
        },
        // 20 year fixed mortgage term
        20: {
          months: 20 * 12,
          interestRate: 3.75,
          isARM: false,
          fixedTerm: 20,
          isChecked: false
        },
        // 15 year fixed mortgage term
        15: {
          months: 15 * 12,
          interestRate: 3.5,
          isARM: false,
          fixedTerm: 15,
          isChecked: true
        },
        // 7 year ARM mortgage term
        7: {
          months: 30 * 12,
          interestRate: 3.75,
          isARM: true,
          fixedTerm: 7,
          isChecked: false
        },
        10: {
          months: 10 * 12,
          interestRate: 3.75,
          isARM: false,
          fixedTerm: 10,
          isChecked: false
        },
        25: {
          months: 25 * 12,
          interestRate: 3.75,
          isARM: false,
          fixedTerm: 25,
          isChecked: false
        }
      },
      validations: {
        // valid fields should be set to 1
        // invalid fields should be set to 0
        mortgageAmount: null,
        mortgageTerm: null,
        interestRates: null
      },
      errors: {
        // each field should contain an array of errors
        mortgageAmount: [],
        mortgageTerm: [],
        interestRates: []
      }
    };
  },
  created: function() {
    this.init();
  },
  methods: {
    init: function() {
      // calculate schedule for default values
      this.mortgageAmount = "320000";

      this.getSchedule();

      // resest values
      this.mortgageAmount = null;
      this.gotSchedule = false;
      this.pdfGenerated = false;
    },

    formatNumber: function(amount) {
      // format number to display 2 decimal digits and add comma seperator
      var formattedAmount = Number(amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return formattedAmount;
    },

    displayInterestRates: function() {
      // loop through all the terms
      for (var term in this.terms) {
        // update the flag to true/false
        if (this.mortgageTerm.includes(term)) this.terms[term].isChecked = true;
        else this.terms[term].isChecked = false;
      }
    },

    setIsComparison: function() {
      if (this.mortgageTerm.length >= 2) this.isComparison = true;
      else this.isComparison = false;
    },

    validateMortgageAmount: function(event) {
      // validate phone number from validationsMixin
      this.errors.mortgageAmount = this.checkPrice(event);

      // set validation-icon
      this.validations.mortgageAmount =
        this.errors.mortgageAmount.length === 0 ? 1 : 0;
    },

    validateMortgageTerm: function() {
      // clear array
      this.errors.mortgageTerm = [];

      // display corresponding interest rate fields
      this.displayInterestRates();

      // check if array is empty
      if (this.mortgageTerm.length === 0)
        this.errors.mortgageTerm.push("mortgage term cannot be empty");

      // set validation-icon
      this.validations.mortgageTerm =
        this.errors.mortgageTerm.length === 0 ? 1 : 0;
    },

    validateInterestRates: function() {
      // clear array
      this.errors.interestRates = [];

      // loop through all the terms
      for (var term in this.terms) {
        if (
          // check if interest rates for checked terms are empty
          this.terms[term].interestRate === null ||
          this.terms[term].interestRate === "" ||
          this.terms[term].interestRate === "0" ||
          // check if values are valid numbers
          isNaN(this.terms[term].interestRate)
        ) {
          this.errors.interestRates.push(
            "please enter a valid interest rate for " +
              term +
              " year mortgage term"
          );
        }
      }

      // set validation-icon
      this.validations.interestRates =
        this.errors.interestRates.length === 0 ? 1 : 0;
    },

    validateForm: function() {
      // loop through all fields in errors
      for (var field in this.errors) {
        // return false if any field has errors
        if (this.errors[field].length !== 0) return false;
      }

      // return true if all fields are validated
      return true;
    },

    getSchedule: function() {
      if (this.validateForm()) {
        // get today's date
        this.today = new Date();
        // clear schedules list
        this.schedules = [];

        // set the comparison flag
        this.setIsComparison();

        // sort the array of mortgage terms selected in ascending order
        this.mortgageTerm.sort(function(a, b) {
          return a - b;
        });

        // get the schedules for each term selected
        this.mortgageTerm.forEach(
          function(term) {
            // unmask mortgage amount
            var unmaskedMortgageAmount = this.unmaskCurrency(
              this.mortgageAmount
            );

            // calculate the schedule for each mortgage term using the Amortization Mixin
            var schedule = this.calculateSchedule(
              unmaskedMortgageAmount,
              this.terms[term].interestRate,
              this.terms[term].months,
              this.terms[term].isARM,
              this.terms[term].fixedTerm
            );

            // store each schedule in array
            this.schedules.push(schedule);
          }.bind(this)
        );

        // set flags
        this.gotSchedule = true;
        this.hideSchedule = false;

        // scroll to schedule
        setTimeout(() => {
          this.$scrollTo(this.$refs.schedule, {
            duration: 500,
            easing: "ease",
            offset: -75
          });
        }, 50);

        // generate PDF and handle promise
        this.generateAmortizationPdf(this.schedules).then(doc => {
          // save pdf
          this.amortizationPdf = doc;
          // set flag
          this.pdfGenerated = true;
        });
      }
    },

    // download a printable pdf copy of the Amortization Schedule
    downloadPDF: function() {
      this.amortizationPdf.save("AmortizationSchedule.pdf");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* form alignment */
form {
  background-color: #f8f8f8;
  margin-bottom: 0;
}

/* font styles for form elements */
form p,
form legend,
form label {
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  margin: 0 0 2% 0;
  color: #464646;
}

/* styles for h1 */
form h1 {
  margin-bottom: 0;
}

/* styles for horizontal break */
hr {
  margin: 4% 0;
}

/* style for Required Asterisk */
.required,
.instruction,
.error {
  text-decoration: none;
  color: #bf4d2e;
  margin: 0;
  padding: 0;
}

.field-name {
  font-weight: bold;
  line-height: 3em !important;
}

/* style for mortgage amount input and labels */
.mortgage-amount > label,
.mortgage-amount > input {
  display: inline-block;
  width: 220px;
  box-sizing: border-box;
}

/* style for mortgage term input and labels */
.mortgage-term label,
.mortgage-term input {
  display: inline-block;
  width: 220px;
  box-sizing: border-box;
}

/* style for interest rate input and labels */
.interest-rate {
  min-height: 125px;
}

.interest-rate .term {
  display: inline-block;
  width: 220px;
  box-sizing: border-box;
  padding: 1% 2% 0 3%;
  position: relative;
  margin: 0;
}

.term > input,
.term > label {
  width: 100px;
  margin-left: 0;
}

/* center align label */
.term > label {
  text-align: center;
}

/* set position for % symbol */
.percentage {
  position: absolute;
  margin-left: 65px;
  top: 12px;
  z-index: 1;
}

/* width for list elements in checkbox */
input[type="checkbox"] {
  width: auto;
}

/* style for list elements in radio/select/checkbox */
fieldset ul {
  margin: 0 0 2% 0;
}

fieldset li {
  display: inline;
  list-style-type: none;
  padding: 0;
}

/* styles for results and summary section */
.result,
summary {
  color: black;
  /*min-height: 500px;*/
}

/* style for heading */
.result h1,
.summary h1 {
  font-size: 60px;
  font-weight: bold;
  color: black;
  text-align: center;
  padding: 1rem 0 3rem 0;
}

.result h2,
.summary h2 {
  font-size: 45px;
  text-align: center;
  color: black;
  font-weight: bold;
  padding-top: 2rem;
}

/* float fields to left */
.float-left {
  text-align: left;
}

/* float fields to right */
.float-right {
  float: right;
}

/* align submit button to right */
form .primary {
  margin: 0 0 0 70%;
}

.download-pdf-wrapper {
  text-align: center;
}

/* TODO align to center*/
.download-pdf {
  display: inline-block;
}

/* styles for show/hide buttons */
.hide,
.show {
  color: #2ebfac;
  cursor: pointer;
  bottom: 0;
  right: 2rem;
  margin: 0;
  text-align: end;
}

/* row height for schedule */
#schedule td,
#schedule th {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

/* heading for schedules when comparing */
.compare-schedules h3 {
  text-align: center;
  color: black;
}

.schedule-heading {
  min-height: 175px;
}

.compare-schedules .schedule-heading {
  padding: 0 2%;
}

.schedule-heading li {
  padding: 0;
}

/* styles for tables */
#schedule .compare-tables {
  padding: 0;
}
</style>