export default {
    methods: {
        calculateMonthlyInterest: function (interestRate) {
            // caclulate monthly interest rate
            var r = interestRate / (12 * 100);
            return r;
        },
        calculateMonthlyPayment: function (loanAmount, numberOfMonths, r) {
            // caclulate monthly interest rate
            var monthlyPayment = loanAmount * ((r * Math.pow((1 + r), numberOfMonths)) / (Math.pow((1 + r), numberOfMonths) - 1));
            return monthlyPayment;
        },
        calculateSchedule: function (loanAmount, interestRate, numberOfMonths, isARM, fixedTerm) {
            // declare and initialize variables
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var monthlySchedule = [];
            var currentDate = new Date();
            var currentMonth = "";
            var totalInterest = 0;
            var totalPrincipal = 0;
            var cumulativeMonthlyInterest = 0;
            // variables for annual schedule
            var annualSchedule = [];
            var annualInterestPaid = 0;
            var annualPrincipalPaid = 0;
            var annualPayment = 0;

            var r = this.calculateMonthlyInterest(interestRate);

            // calculate monthly payment
            var monthlyPayment = this.calculateMonthlyPayment(loanAmount, numberOfMonths, r);

            // initial loan balance is same as the loan amount
            var loanBalance = loanAmount;

            // calculate monthly schedule
            for (var j = 1; j <= numberOfMonths; j++) {

                if (isARM && (((j - 1) / 12) === fixedTerm)) {
                    interestRate += Number(5);
                    r = this.calculateMonthlyInterest(interestRate);
                    monthlyPayment = this.calculateMonthlyPayment(loanBalance, numberOfMonths - (j - 1), r);
                }

                // increment current month
                currentDate.setMonth(currentDate.getMonth() + 1);

                // get current month name and year
                currentMonth = months[currentDate.getMonth()] + " " + currentDate.getFullYear();

                // calculate interest amount for current month
                var interestAmount = loanBalance * r;

                // calculate cumulative interest paid
                cumulativeMonthlyInterest += interestAmount;

                // calculate principal amount for current month
                var principalAmount = monthlyPayment - interestAmount;

                // deduct principal amount paid from loan balance
                loanBalance -= principalAmount;

                // add current month details to schedule
                monthlySchedule.push({
                    currentMonth,
                    monthlyPayment: monthlyPayment.toFixed(2),
                    interestRate,
                    monthlyInterestPaid: interestAmount.toFixed(2),
                    cumulativeMonthlyInterest,
                    monthlyPrincipalPaid: principalAmount.toFixed(2),
                    loanBalance: Math.abs(loanBalance).toFixed(2)
                });

                // calculate the total principal paid so far
                totalPrincipal += principalAmount;

                // calculate the total interest paid so far
                totalInterest += interestAmount;

                // calculate total amount of interest paid annualy
                annualInterestPaid += interestAmount;

                // calculate total amount of principal paid annualy
                annualPrincipalPaid += principalAmount;

                // calculate annual payment
                annualPayment += monthlyPayment;

                // push schedule for every 12 months
                if (j % 12 === 0) {
                    annualSchedule.push({
                        currentYear: currentDate.getFullYear() - 1 + " - " + currentDate.getFullYear(),
                        monthlyPayment: monthlyPayment.toFixed(2),
                        annualPayment: annualPayment.toFixed(2),
                        interestRate,
                        annualInterestPaid: annualInterestPaid.toFixed(2),
                        cumulativeAnnualInterest: cumulativeMonthlyInterest.toFixed(2),
                        annualPrincipalPaid: annualPrincipalPaid.toFixed(2),
                        annualBalance: Math.abs(loanBalance).toFixed(2)
                    });
                    // reset values after 12 months
                    annualPayment = 0;
                    annualInterestPaid = 0;
                    annualPrincipalPaid = 0;
                }
            }

            return {
                term: fixedTerm + (isARM ? " years ARM" : " years"),
                interestRate: monthlySchedule[0].interestRate,
                isARM,
                totalInterest: totalInterest.toFixed(2),
                totalPrincipal: totalPrincipal.toFixed(2),
                monthlySchedule,
                annualSchedule
            };
        }
    }
}